import React, { Component } from 'react';
import CustomNavbar from './component/CustomNavbar';
import $ from 'jquery';
import Banner3 from './component/Banner/Banner4';
import Footer from './component/Footer/Footer';
import WOW from 'wowjs';
import ReactHighcharts from 'react-highcharts/ReactHighstock.src'
import moment from 'moment'
import web3 from './constants/web3';
import lockToken from './constants/lockToken';
import {ERC20ABI} from './constants/erc20abi';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
const CoinGecko = require('coingecko-api');

class Explorer extends Component {

    state = {
        marketData: [0,0,0],
        contractAddress: '',
        tokenBalance:'',
        totalSupply:'',
        price:'',
        tokensLockedPercentage:'',
        name:'',
        image:'',
        href: '',
        loading: true

    }
    componentDidMount(){
        const { smartcontractaddress } = this.props.match.params;
        this.fetchMajorData(smartcontractaddress)
        this.setState({contractAddress:smartcontractaddress})

        if (typeof window !== 'undefined') {
            const wow = new WOW.WOW({
              live: false,
            })
            wow.init()
          }
            var head = $(".navbar");
            var stick = "shrink";
    
            $(window).scroll(function() {
                $(window).scrollTop() > 0
                    ? head.addClass(stick)
                    : head.removeClass(stick)
            })
    
            $('.navbar li a').on('click', function(event) {
              var $anchor = $(this);
              console.log($anchor.attr('href'))
              if($anchor.attr('href') != "/BSCrypt_Audit_Report_-_QuillAudits.pdf"){
                $('html, body').stop().animate({
                  scrollTop: $($anchor.attr('href')).offset().top-75
              }, 1500);
              event.preventDefault();
              }
  
          });
      }
        




    fetchMajorData = async(smartcontractaddress) => {
        try{
            const CoinGeckoClient = new CoinGecko();
            // method getTotalTokenBalance
            let totalTokenBalance = await lockToken.methods.getTotalTokenBalance(smartcontractaddress).call();
            const erc20token = new web3.eth.Contract(ERC20ABI,smartcontractaddress);
            let decimals = await erc20token.methods.decimals().call();
            let name = await erc20token.methods.name().call();
            
            let totalSupply = await erc20token.methods.totalSupply().call();

            let percentage = totalTokenBalance/totalSupply*100;
            if(percentage < 0.1){
                percentage = "<0.1"
            } else {
                percentage.toFixed(2);
            }
            let price = await CoinGeckoClient.simple.price({
                ids: [name],
                vs_currencies: ['usd'],
            });

            if(Object.keys(price.data).length != 0){
                price = parseFloat(price.data[Object.keys(price.data)[0]].usd).toLocaleString('en-US', {maximumFractionDigits:2});
            } else {
                price = 0;
            }

            const multiplier = Math.pow(10,decimals);
            totalSupply = (totalSupply / multiplier);
            totalSupply = parseFloat(totalSupply).toLocaleString('en-US', {maximumFractionDigits:decimals});
            let href = `https://bscscan.com/token/${smartcontractaddress}`
            totalTokenBalance = (totalTokenBalance / multiplier)
            totalTokenBalance = parseFloat(totalTokenBalance).toLocaleString('en-US', {maximumFractionDigits:decimals});
            
            this.fetchImage(name)
            this.fetchData(name)
            this.setState({name:name,price:price, tokenBalance:totalTokenBalance, totalSupply:totalSupply, tokensLockedPercentage:percentage, href:href, loading:false})

        } catch(err){
          this.setState({loading:false})
            console.log(err.message);
        }
    }

    testImage = async(url, timeoutT) => {
      return new Promise(function (resolve, reject) {
          var timeout = timeoutT || 5000;
          var timer, img = new Image();
          img.onerror = img.onabort = function () {
              clearTimeout(timer);
              resolve("error")
          };
          img.onload = function () {
              clearTimeout(timer);
              resolve("success");
          };
          timer = setTimeout(function () {
              // reset .src to invalid URL so it stops previous
              // loading, but doesn't trigger new load
              img.src = "//!!!!/test.jpg";
              resolve("error");
          }, timeout);
          img.src = url;
      });
  }


  fetchImage = async(name) => {
    let image;

              image = `https://bscscan.com/token/images/${name}_32.png`
              let exists =  await this.testImage(image);
              if(exists == "error"){
                  image = 'https://bscscan.com/images/main/empty-token.png'
                  this.setState({image:image})
                } else {
                  this.setState({image:image})
                }
  }

    fetchData = async(tokenName) => {
        try{
            const CoinGeckoClient = new CoinGecko();
            let data = await CoinGeckoClient.coins.fetchMarketChart(tokenName);
            if(data.success == true){
                this.setState({marketData:data.data.prices})
            }
        }
        catch(err){
            console.log(err);
        }

    }





    render() {
        const options = {style: 'currency', currency: 'USD'};
        const numberFormat = new Intl.NumberFormat('en-US', options);


        const configPrice = {
      
            yAxis: [{
              offset: 20,
      
              labels: {
                formatter: function () {
                  return numberFormat.format(this.value) 
                }
                ,
                x: -15,
                style: {
                  "color": "#000", "position": "absolute"
                },
                align: 'center'
              },
            },
              
            ],
            tooltip: {
              shared: true,
              formatter: function () {
                return numberFormat.format(this.y, 0) +  '</b><br/>' + moment(this.x).format('MMMM Do YYYY, h:mm')
              }
            },
            plotOptions: {
              series: {
                showInNavigator: true,
                gapSize: 6,
      
              }
            },
            rangeSelector: {
              selected: 1
            },
            title: {
              text: `Historical price`
            },
            chart: {
              height: 600,
            },
        
            credits: {
              enabled: false
            },
        
            legend: {
              enabled: true
            },
            xAxis: {
              type: 'date',
            },
            rangeSelector: {
              buttons: [{
                type: 'day',
                count: 1,
                text: '1d',
              }, {
                type: 'day',
                count: 7,
                text: '7d'
              }, {
                type: 'month',
                count: 1,
                text: '1m'
              }, {
                type: 'month',
                count: 3,
                text: '3m'
              },
                {
                type: 'all',
                text: 'All'
              }],
              selected: 4
            },
            series: [{
              name: 'Price',
              type: 'spline',
        
              data: this.state.marketData,
              tooltip: {
                valueDecimals: 2
              },
        
            }
            ]
          };




        return (
            <div className="App">
             <CustomNavbar/>
                <Banner3/>

                <div class="container">
                <br /> <br />
                <div class="row">
                    <div class="col-sm">
                    <h4> 
                      {/* <img src={this.state.image} width="32" />  */}
                    {this.state.loading == true ? <><div class="spinner-border" role="status">
  <span class="visually-hidden">Loading...</span>
</div></> : this.state.name}
                    
                    </h4>
                    <BrowserView>
                    <h6>
                      <a href={this.state.href} target="_blank" style={{color:'blue', width:'100%'}} > {this.state.contractAddress} </a>
                    </h6>
                    </BrowserView>
                    <MobileView>
                    <h6 style={{fontSize:'14px', marginTop:'5%',marginBottom:'5%'}}>
                      <a href={this.state.href} target="_blank" style={{color:'blue', width:'100%'}} > {this.state.contractAddress} </a>
                    </h6>
                    </MobileView>
                    </div>
                    <div class="col-sm">
                    <a href="/lock" style={{textDecoration:'none'}}>
                    <button type="button" class="btn btn-dark">Lock this Token</button>
                    </a>
                    </div>
                </div>  
                
                <br />
                <div class="row">
                <div class="col-sm">
                <div class="card">
                    <div class="card-body">
                        <h5 class="card-title">Lockup Overview</h5>
                        <p class="card-text">View liquidity and {this.state.name} lockup information</p>
                        <hr />
                        <div class="row">
                            <div class="col-sm">
                                <span style={{fontWeight:'bold'}}>{this.state.name} Locked</span>
                            </div>
                            <div class="col-sm">
                                {this.state.tokenBalance}
                            </div>
                    </div>
                    <hr />
                    <div class="row">
                            <div class="col-sm">
                                <span style={{fontWeight:'bold'}}>{this.state.name} Locked (%)</span>
                            </div>
                            <div class="col-sm">
                                {this.state.tokensLockedPercentage}
                            </div>
                    </div>
                    <hr />
                    <div class="row">
                            <div class="col-sm">
                                <span style={{fontWeight:'bold'}}>Total Supply</span>
                            </div>
                            <div class="col-sm">
                                {this.state.totalSupply}
                            </div>
                    </div>
                    <hr />
                    <div class="row">
                            <div class="col-sm">
                                <span style={{fontWeight:'bold'}}>Token Price</span>
                            </div>
                            <div class="col-sm">
                                ${this.state.price}
                            </div>
                    </div>
                    </div>
                </div>
                </div>
                </div>
                <div className="container" style={{width:"100%",margin: "0 auto"}}>
                <ReactHighcharts style={{width:'100%'}} config = {configPrice}></ReactHighcharts>
                </div>
                </div>

                <br /> <br />

                <Footer/>
            </div>

        )


}

}
export default Explorer;