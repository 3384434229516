/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react';

class ServiceList extends Component{

    render(){
        return(
            <div className="col-lg-12 col-md-12">
                <div className="seo_service_item">
                    {/* <img src={require('../images/BSCRYPT ROADMAP.png')} alt="" /> */}
                    <a href="#">
                        {/* <h2>Farming</h2> */}
                    </a>
                    {/* <p>BSCRYPT brings you block-by-block rewards through yield farming with the promise to offer the most competitive rewards (APYs)</p> */}
                    {/* <hr style={{width:'50%'}} /> */}
                    {/* <p>The amount of BSCRYPT you get per day depends on the valuation of your crypto assets in US dollars.</p> */}
                    <div>
                    <ol style={{listStylePosition:'inside'}}>
                        {/* <li>Choose a farming pool on <a href="#">BSCRYPT Farming </a></li> */}
                        {/* <li>If you don't have LP tokens already, join a liquidity pools on <a target="_blank" href="https://exchange.pancakeswap.finance/#/pool">Pancakeswap</a></li> */}
                        {/* <li>Stake your LP tokens in our farming pools and earn BSCRYPT rewards</li> */}
                    </ol>
                    </div>
                    {/* <button className="btn btn-warning">Go to Farming</button> */}
                </div>
            </div>
        )
    }
}

export default ServiceList;