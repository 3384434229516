import React, { Component } from 'react';
class BannerContent extends Component {
    render(){
        var { name, topic} = this.props;
        return(
            <React.Fragment>
                <div className="n_banner_content">
                    <h2 className="wow fadeInUp" data-wow-delay="300ms">{name}</h2>
                    <p className="wow fadeInUp" data-wow-delay="500ms">{topic}</p>
                </div>
            </React.Fragment>
            
        );
    }
}

export default BannerContent;