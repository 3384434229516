/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer } from 'recharts';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";


const data = [
    { name: 'Presale', value: 50000000 },
    { name: 'Lock on Pancake-Swap', value: 20000000 },
    { name: 'Marketing	', value: 20000000 },
    { name: 'Development', value: 10000000 },
    { name: 'Initial Farming Pool', value: 100000000 },

  ];

  const data01 = [
    { name: 'Presale', value: 50000000 },
    { name: 'Lock on Pancake-Swap', value: 20000000 },
    { name: 'Marketing	', value: 20000000 },
    { name: 'Development', value: 10000000 },
    { name: 'Initial Farming Pool', value: 100000000 },
  ];
  const data02 = [
    { name: 'Presale', value: 50000000 },
    { name: 'Lock on Pancake-Swap', value: 20000000 },
    { name: 'Marketing	', value: 20000000 },
    { name: 'Development', value: 10000000 },
    { name: 'Initial Farming Pool', value: 100000000 },
  ];

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
        <g style={{textAlign:'center'}}>
          {/* <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
            {payload.name}
          </text> */}
          <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={"red"}
          />
          <Sector
            cx={cx}
            cy={cy}
            startAngle={startAngle}
            endAngle={endAngle}
            innerRadius={outerRadius + 6}
            outerRadius={outerRadius + 10}
            fill={"orange"}
          />
          <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
          <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
          <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${payload.name}`}</text>
          <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#=">
            {`(${(percent * 100).toFixed(2)}%)`}
          </text>
        </g>
      );
    };

    
class TokenEconomics extends Component{

    state = {
        activeIndex: [0,1,2,3,4],
      };

      onPieEnter = (_, index) => {
        this.setState({
          activeIndex: index,
        });
      };

    render() {
        return (
            <div className="col-lg-12 col-md-12">
                <p>
                    <h2>BSCRYPT Token Economics</h2>
                    <table class="table table-dark table-hover">
                        <tbody>
                            <tr>
                            <th scope="row">Total Supply</th>
                            <td>200,000,000</td>
                            </tr>
                            <tr>
                            <th scope="row">Presale</th>
                            <td>50,000,000</td>
                            </tr>
                            <tr>
                            <th scope="row">Permanent Liquidity Lock on Pancake-Swap</th>
                            <td>20,000,000 $BSCRYPT</td>
                            </tr>
                            <tr>
                            <th scope="row">Marketing</th>
                            <td>20,000,000 $BSCRYPT</td>
                            </tr>
                            <tr>
                            <th scope="row">Development</th>
                            <td>10,000,000 $BSCRYPT (Time locked via BSCrypt)</td>
                            </tr>
                            <tr>
                            <th scope="row">Initial Farming Pool</th>
                            <td>100,000,000 $BSCRYPT</td>
                            </tr>
                            <tr>
                            <th scope="row">Min amount per Wallet</th>
                            <td>0.1 BNB</td>
                            </tr>
                            <tr>
                            <th scope="row">Max Cap per Wallet</th>
                            <td>50 BNB</td>
                            </tr>
                            <tr style={{background:'orange'}}>
                            <td colspan="2" style={{textAlign:'center', width:'100%'}}>1 BNB = 9756.09756 $BSCRYPT</td>
                            </tr>
                        </tbody>
                    </table>
                </p>
                {/* <h4 style={{marginTop:'5%', marginBottom:'-5%'}}>Funds Distribution (Visualized)</h4>


                <div style={{width:'100%',height:'500px'}}>
                <div style={{width:'100%',height:'100%'}}>
                <ResponsiveContainer width="100%" height="100%">
                <PieChart width={550} height={550} >
                            <Pie
                                activeIndex={this.state.activeIndex}
                                activeShape={renderActiveShape}
                                data={data}
                                cx="50%"
                                cy="50%"
                                innerRadius={60}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                                onMouseEnter={this.onPieEnter}
                            />
                            </PieChart>
                </ResponsiveContainer>
                </div>
                </div> */}
            </div>
        )
    }
}

 export default TokenEconomics;
                    