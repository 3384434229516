import React, { Component } from 'react';
import WOW from "wowjs";
import FrontPageExplorer from '../../component/FrontPageExplore';
import "./tablecss.css";

class Banner extends Component {
    componentDidMount() {
    const wow = new WOW.WOW();
        wow.init();
    }
    render() {
        // var {name, topic} = this.props;
        return (
            <section className="n_hero_banner_area2 " style ={ { backgroundImage: "-moz-linear-gradient( 40deg, #1c46df 0%, #18d8f6 100%),-webkit-linear-gradient( 40deg, #1c46df 0%, #18d8f6 100%), -ms-linear-gradient( 40deg, #1c46df 0%, #18d8f6 100%)" } } id="home">
                       <div className="mobile-space">
                        <FrontPageExplorer />
                        </div>
            </section>
            );
        }
    }

export default Banner;

                
