import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import lockToken from '../constants/lockToken';
import web3 from '../constants/web3';
// import Web3 from 'web3';
// import Web3Modal from 'web3modal';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";




class CustomNavbar extends Component {

    state = {
        chainId:'56',
        address: '',
        isUnlocked: '',
        connected: false,
    }

    componentDidMount(){
        
        this.connect()

        this.accountChange()
          
    }

    accountChange = async() => {
        try{
            window.ethereum.on("accountsChanged", async function() {
                // Time to reload your interface with accounts[0]!
                let accounts = await web3.eth.getAccounts();
                // accounts = await web3.eth.getAccounts();
                window.location.reload();
              });
  
              window.ethereum.on('chainChanged', (_chainId) => {
                  window.location.reload();
              })
        }
        catch(err){
            console.log(err)
        }




    }

    connect = async() => {
        try{
            
            const accounts = await web3.eth.getAccounts();

            const address = accounts[0]

            const chainId = await window.ethereum.chainId;
            let isUnlocked;
            let permissions;
            if(address.length != 0 ){
                isUnlocked = true;
                this.setState({connected:true})
            }
            // if(window.ethereum_metamask != undefined){
            //     isUnlocked = await window.ethereum._metamask.isUnlocked()
            //     permissions = await window.ethereum.request({
            //         method: 'wallet_getPermissions',
            //         params: [{ eth_accounts: {} }],
            //         })
            //     if(permissions[0].caveats[1].value.includes(address.toLowerCase())){
            //             this.setState({connected:true})
            //     } else {
            //             this.setState({connected:false})
            //     }
            // } else {
            //     isUnlocked = true;
            //     this.setState({connected:true})
            // }





            this.setState({chainId: chainId, address:address, isUnlocked:isUnlocked})
            

        } catch(err){
            console.log(err);
        }
    }

    connectEnabled = async() => {
        await window.ethereum
    .request({
      method: 'wallet_requestPermissions',
      params: [{ eth_accounts: {} }],
    })

    window.location.reload();


    }


    render() {


        
        return (
            <nav id="fixed-top" className="navbar navbar-toggleable-sm transparent-nav navbar-expand-lg">                                                                  
            {/* src={require('../images/log.png')} */}
                <div className="container"><Link to="/"><span className="navbar-brand"><img src={require('../images/bscrypt_logo.png')} width="100%" alt="logo" /><img src={require('../images/bscrypt_logo_dark.png')} alt="logo" /></span></Link><button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="icon-bar"></span><span className="icon-bar"></span><span className="icon-bar"></span></button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item dropdown submenu"><a className="nav-link" href="/" role="button">Home</a>
                            
                            </li>
                            <li className="nav-item"><a className="nav-link" href="/BSCrypt_Audit_Report_-_QuillAudits.pdf" download>Audit</a></li>
                            <li className="nav-item"><a className="nav-link" href="/explorer">Explore</a></li>
                            {/* <li className="nav-item"><a className="nav-link" href="/#faq">FAQ</a></li> */}
                            <li className="nav-item"><a className="nav-link" href="/about">About</a></li>
                            <li className="nav-item"><a className="nav-link" href="/lock">Lockup</a></li>

                        </ul>
                        {this.state.isUnlocked != true ? (<div class="alert alert-danger" role="alert">
  Please unlock your wallet
</div>) : this.state.chainId != 56 && this.state.chaindId != "0x38" ? <a target="_blank" href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain"><div class="alert alert-danger" role="alert">
  You need to connect to <span style={{textDecoration:'underline'}}>binance smart chain</span>
</div></a> :  this.state.connected != true ? <button href="./" className="get-btn" onClick={this.connectEnabled}>Connect Wallet</button> : <div class="alert alert-light" role="alert">
   <BrowserView>
  {this.state.address}
  </BrowserView>
  <MobileView>
      <span style={{fontSize:'12px'}}>  {this.state.address}</span>
  </MobileView>
</div>}
                    </div>
                </div>
            </nav>
        );
    }
}

export default CustomNavbar;