import React, { Component } from 'react';
import Title from './Title';
import web3 from '../constants/web3';
import lockToken from '../constants/lockToken';
import {Modal, Button} from 'react-bootstrap';
import {ERC20ABI} from '../constants/erc20abi';
import payContract from '../constants/payContract';

class Features extends Component {
    state = {
        address: '',
        amount: '',
        days: 0,
        allowanceError:false,
        allowed:'',
        loadingAllowance: false,
        tokenName: '-',
        tokenBalance:'-',
        hasPaid: true
    }
    

    componentDidMount(){
        this.checkBalance();
    }


    checkBalance = async() => {
        let account = await web3.eth.getAccounts();
        const paid = await payContract.methods.payments(account[0]).call();
        if(paid == false){
            this.setState({hasPaid:false})
        }
    }

    payLock = async() =>{
        try{
            let account = await web3.eth.getAccounts();
            const x = await payContract.methods.pay().send({
                from: account[0],
                value: 300000000000000000
            })
            alert("You have successfully unlocked!")
            window.location.reload();
        }catch(err){

        }
    }

    lockToken = async() => {
        try{
            let account = await web3.eth.getAccounts();
            // get days to unixtimestamp * 1000
            var today = new Date();
            const days = parseInt(this.state.days)
            var futureDate = today.getDate()+days;
            let timestamp = (today.setDate(futureDate))/1000
            timestamp = parseInt(timestamp)
            let amount = this.state.amount
            const address = this.state.address
            const erc20token = new web3.eth.Contract(ERC20ABI,address);
            const decimals = await erc20token.methods.decimals().call();
            amount = this.strtodec(amount, decimals);
            const y = await erc20token.methods.allowance(account[0],"0xa36037dC26C5C02e864eBA969A312320E6487269").call()
            if(y < amount){
                this.setState({allowanceError:true})
                throw "execution reverted: ERC20: transfer amount exceeds allowance"
            }
            const x = await lockToken.methods.lockTokens(address, amount, timestamp).send({
                from: account[0]
            });

            alert("You have successfully locked your tokens")
            window.location.reload();

        } catch(err){
            
        }
    }

    allowToken = async() => {
        try{
            this.setState({loadingAllowance:true})
            let account = await web3.eth.getAccounts();
            const address = this.state.address;
            const erc20token = new web3.eth.Contract(ERC20ABI,address);
            let amountAllowed = this.state.allowed;

            const decimals = await erc20token.methods.decimals().call();
            amountAllowed = this.strtodec(amountAllowed, decimals);
            console.log(address, amountAllowed);
            console.log(account);
            const x = await erc20token.methods.approve("0xa36037dC26C5C02e864eBA969A312320E6487269",amountAllowed).send({
                from: account[0],
                value: 0
            })
            console.log(x)
            this.setState({loadingAllowance:false, allowanceError:false})
            alert("You have successfully approved us to lock your tokens!")



        } catch(err){
            this.setState({loadingAllowance:false})
            alert("something went wrong")
            console.log(err);
        }
    }

    updateTokenDetails = async(event) => {
        try{
            console.log(event.target.value)
            const theaddress = event.target.value;
            this.setState({ address: event.target.value })
            const erc20token = new web3.eth.Contract(ERC20ABI,theaddress);
            let account = await web3.eth.getAccounts();
            const tokenName = await erc20token.methods.name().call();
            let balance = await erc20token.methods.balanceOf(account[0]).call();
            const decimals = await erc20token.methods.decimals().call();
            const multiplier = Math.pow(10,decimals);
            balance = (balance / multiplier);
            balance = parseFloat(balance).toFixed(2);
            this.setState({tokenName: tokenName, tokenBalance: balance})
        }
        catch(err){
            console.log(err)
            this.setState({tokenName: "-", tokenBalance: "-"})

        }   
    }


    // converts value to value with decimals
    // eg, amount = 1, decimals = 4
    // returns 10000
    strtodec = (amount, dec) =>{
        var i = 0;
        if (amount.toString().indexOf('.') != -1) {
            i = amount.toString().length - (amount.toString().indexOf('.') + 1);
        }
        let stringf = amount.toString().split('.').join('');
        if (dec<i){
            console.warn("strtodec: amount was truncated")
            stringf = stringf.substring(0,stringf.length - (i-dec));
        } else {
            stringf = stringf + "0".repeat(dec-i);
        }
        return stringf;
    }
    render() {
        return (
            <section className="best_screen_features_area features_area_pad" id="lock">
                <svg xmlns="http://www.w3.org/2000/svg">
                    <path fill="#fff" id="down_bg_copy_2" data-name="down / bg copy 2" className="cls-1" d="M444.936,252.606c-148.312,0-305.161-29.63-444.936-80.214V0H1920V12S808.194,234.074,444.936,252.606Z" />
                </svg>
                <ul className="memphis-parallax hidden-xs hidden-sm white_border">
                    <li data-parallax='{"x": -00, "y": 100}'><img className="br_shape" src={require('../images/line/f_l_01.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 200, "y": 200}'><img className="br_shape" src={require('../images/line/f_l_02.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 150, "y": 150}'><img className="br_shape" src={require('../images/line/f_l_03.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 50, "y": 50}'><img className="br_shape" src={require('../images/line/f_l_05.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 150, "y": 150}'><img src={require('../images/line/f_l_06.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 100, "y": 100}'><img src={require('../images/line/f_l_07.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 50, "y": 50}'><img src={require('../images/line/f_l_08.png')} alt="f_img"/></li>
                    <li data-parallax='{"y": 250}'><img src={require('../images/line/f_l_09.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 250, "y": 250}'><img src={require('../images/line/f_l_10.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 150, "y": 150}'><img className="br_shape" src={require('../images/line/f_l_04.png')} alt="f_img"/></li>
                </ul>
                <div className="container">
                    <Title Stitle="Create your own custom lock now" pfont="All coins are locked into our audited smart contract and can only be withdrawn by you after lock time expires."/>
                    <div className="row ">
                        <div className="col-lg-3">
                        </div>
                        <div className="col-lg-6 col-xs-12" style={{textAlign:'center'}}>
                <div class="card text-center" style={{ borderRadius:'25px'}}>
                    <div class="card-header" style={{background:'darkblue',color:'white', borderRadius:'25px'}}>
                    <i className="ti-lock" style={{fontSize:'25px',color:'white'}}></i> Create New Lock
                    </div>
                    <div class="card-body" style={{border:'1px solid transparent'}}>
                        <h5 class="card-title">
                        {/* <i className="ti-lock" style={{fontSize:'35px', color:'darkblue'}}></i> */}
                        <h5>
                        Enter the token address you would like to lock
                        </h5>
                        <h5>on</h5>
                        <img src="https://dex-bin.bnbstatic.com/static/images/chain-logo.svg"/>
                        </h5>
                        <input type="text" class="form-control" id="address" placeholder="0xB8c77482e45F1F44dE1745F52C74426C631bDD52" onChange={this.updateTokenDetails} />
                           <div class="row">
                        <div class="col" style={{textAlign:'left'}}>
                            <p>
                                Token: <span> {this.state.tokenName}</span>
                            </p>
                        </div>
                        <div class="col">
                            <p>
                                Balance: <span>{this.state.tokenBalance}</span>
                            </p>
                        </div>
                        </div>
                        <br />
                        <input type="text" class="form-control" id="lockamount" placeholder="Lock Amount: 500" onChange={event =>
                            this.setState({ amount: event.target.value })
                          }/>
                        <br />
                        <div class="input-group mb-3">
                        <span class="input-group-text" id="basic-days">Days</span>
                        <input type="text" class="form-control" placeholder="90" aria-label="days" aria-describedby="basic-days" onChange={event =>
                            this.setState({ days: event.target.value })
                          } />
                        </div>
                        {this.state.hasPaid == false ?   (<div>  
                            <p>To unlock BSCRYPT lock feature, you must first pay 0.3 BNB. Infinite locks on payment.</p>
                            <button class="btn btn-outline-warning" onClick={this.payLock}>Pay</button>
                             </div>)
 :                         <button class="btn btn-outline-warning btn-lg" onClick={this.lockToken}>Lock</button>}
                    </div>
                    <div class="card-footer" style={{background:'darkblue',color:'white', borderRadius:'25px'}}>
                    By locking tokens into this smart contract you agree to our Terms of Service.
                    </div>
                    </div>

                    <Modal show={this.state.allowanceError} onHide={() => this.setState({allowanceError:false})}>
        <Modal.Header closeButton>
          <Modal.Title>ERROR! Don't worry, we've got you covered</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <p>
                You must allow our smart contract to lock your tokens. Please allow an amount equal or higher to what you plan to lock
                </p>
                <hr />
                <p>
                    Token Balance: <span style={{fontWeight:'bold'}}> {this.state.tokenBalance}</span>
                </p>
                <input type="text" class="form-control" id="amount" placeholder="1000" onChange={event =>
                            this.setState({ allowed: event.target.value })
                          } />
                          <br />
                                <button style={{width:'100%'}} class="btn btn-outline-warning" onClick={this.allowToken}>
                                    {this.state.loadingAllowance ? <div class="spinner-border" role="status">
  <span class="visually-hidden">Loading...</span>
</div> : "Allow" }
                                    </button>

            </div>
            </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => this.setState({allowanceError:false})} >
            Close
          </Button>
        </Modal.Footer>
      </Modal>


            </div>
                        </div>
                    </div>
            </section>
        );
    }
}

export default Features;