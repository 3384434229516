import React, { Component } from 'react';
import BannerContent from './BannerContent2';
import WOW from "wowjs";


class Banner extends Component {
    componentDidMount() {
    const wow = new WOW.WOW();
        wow.init();
    }
    render() {
        // var {name, topic} = this.props;
        return (
            <section className="n_hero_banner_area2" style ={ { backgroundImage: "-moz-linear-gradient( 40deg, #1c46df 0%, #18d8f6 100%),-webkit-linear-gradient( 40deg, #1c46df 0%, #18d8f6 100%), -ms-linear-gradient( 40deg, #1c46df 0%, #18d8f6 100%)" } } id="home">
                <svg id="hero_shape2_normal" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                        <linearGradient id="PSgrad_0" x1="0%" x2="76.604%" y1="64.279%" y2="0%">
                            <stop offset="0%" stopColor="rgba(29,62,222, 0.20)"></stop>
                            <stop offset="100%" stopColor="rgba(3,218,246, 0.20)"></stop>
                        </linearGradient>
                    </defs>
                </svg>
                
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                        <div className="col-lg-12" style={{textAlign:'center'}}>
                <img src={require('../../images/BSCrypt Lock.png')} alt="logo" width />
                </div>
                            <BannerContent name="Create BSCrypt Lock" topic="You are steps away from creating your lock"/>
                        </div>
                    </div>
                </div>
                <div className="shape_banners">
                    <img className="img1 wow fadeInRight" data-wow-delay="1.6s" src={ require('../../images/home/3dline.png')} alt="f_img" />
                    <img className="img2 wow fadeInRight" data-wow-delay="1.2s" src={require ('../../images/home/3dline2.png')} alt="f_img" />
                    <img className="img3 wow fadeIn" data-wow-delay="1.5s" src={require('../../images/home/3D.png')} alt="f_img" />
                </div>
            </section>
            );
        }
    }

export default Banner;

                
