import React, { Component } from 'react';
class BannerContent extends Component {
    render(){
        var { name, topic} = this.props;
        return(
            <React.Fragment>
                <div className="n_banner_content">
                    <h2 className="wow fadeInUp" data-wow-delay="300ms">{name}</h2>
                    <p className="wow fadeInUp" data-wow-delay="500ms">{topic}</p>
                    <a  href="#lock" style={{textDecoration:'none'}}>
                            <button type="button" class="btn btn-outline-warning">Lock</button>
                    </a>

                    <p>Notice: BSCRYPT does not support locking of deflationary tokens unless you whitelist our lockToken contract address 0xa36037dC26C5C02e864eBA969A312320E6487269
                        otherwise, consider it burned</p>

                </div>
            </React.Fragment>
            
        );
    }
}

export default BannerContent;