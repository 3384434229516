
import React, { Component } from 'react';

import CustomNavbar from './component/CustomNavbar';
import Banner_Aboutus from './component/Banner/Banner_about';
import About_component from './component/About';
import Footer from './component/Footer/Footer';
import $ from 'jquery';
import WOW from 'wowjs';
class About extends Component {

    componentDidMount(){
        if (typeof window !== 'undefined') {
          const wow = new WOW.WOW({
            live: false,
          })
          wow.init()
        }
          var head = $(".navbar");
          var stick = "shrink";
  
          $(window).scroll(function() {
              $(window).scrollTop() > 0
                  ? head.addClass(stick)
                  : head.removeClass(stick)
          })
  
          $('.navbar li a').on('click', function(event) {
              var $anchor = $(this);
              console.log($anchor.attr('href'))
              if($anchor.attr('href') != "/BSCrypt_Audit_Report_-_QuillAudits.pdf"){
                $('html, body').stop().animate({
                  scrollTop: $($anchor.attr('href')).offset().top-75
              }, 1500);
              event.preventDefault();
              }
  
          });
      }

    render() {
        return (
            <div className="App">
                    <CustomNavbar/>
                    <Banner_Aboutus />
                    <About_component />
                    {/* <hr /> */}
                    <Footer />
            </div>
        )
    }

}

export default About;