import React, { Component } from 'react';
import web3 from '../constants/web3';
import lockToken from '../constants/lockToken';
import {ERC20ABI} from '../constants/erc20abi';
import MaterialTable from "material-table";
import '../App.css';
import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile
  } from "react-device-detect";

const CoinGecko = require('coingecko-api');

class FrontPageExplorer extends Component {

    
    state = {
        allDeposits:[],
        loading: false,
    }

    componentDidMount(){
        this.getAll()
    }


    getAll = async() => {
        try{
            this.setState({loading:true})
            let x = await lockToken.methods.getAllDepositIds().call();
    
            let objects = [];
            const CoinGeckoClient = new CoinGecko();
            for(let i=0; i< x.length; i++){
                let obj = {}
                // address 
                let y = await lockToken.methods.getDepositDetails(x[i]).call();
    
                // total token balance locked 
                let z = await lockToken.methods.getTotalTokenBalance(y[0]).call();
    
    
                const erc20token = new web3.eth.Contract(ERC20ABI,y[0]);
                let totalSupply = await erc20token.methods.totalSupply().call();
                let decimals = await erc20token.methods.decimals().call();
                let name = await erc20token.methods.name().call();
                const multiplier = Math.pow(10,decimals);
                z = (z / multiplier);
                z = parseFloat(z).toFixed(2);
                totalSupply = (totalSupply / multiplier);
                totalSupply = parseFloat(totalSupply).toFixed(2);
                let percentage = z/totalSupply*100;
                if(percentage < 0.1){
                    percentage = "<0.1"
                } else {
                    percentage.toFixed(2);
                }
                
                let price = await CoinGeckoClient.simple.price({
                    ids: [name],
                    vs_currencies: ['usd'],
                });
    
                if(Object.keys(price.data).length != 0){
                    price = parseFloat(price.data[Object.keys(price.data)[0]].usd).toLocaleString('en-US', {maximumFractionDigits:2});
                } else {
                    price = 0;
                }
    
                obj["price"] = "$"+ price
                obj["address"] = y[0]
                obj["total"] = parseFloat(z).toLocaleString('en-US', {maximumFractionDigits:2});
                obj["totalSupply"] = parseFloat(totalSupply).toLocaleString('en-US', {maximumFractionDigits:2});
                obj["decimals"] = decimals;
                obj["name"] = name;
                obj["totalpercent"] = percentage + "%";
                objects.findIndex((obj) => obj.address === y[0]) === -1 && objects.push(obj);
    
            }
    
            console.log(objects)
            this.setState({allDeposits:objects, loading:false})
        } catch(err){
            this.setState({loading:false})

        }
      
    }


    goToPage = (theAddress) => {
        window.history.replaceState(null, "Explorer", `/explorer/${theAddress}`)
        window.location.reload(false);

    }


    render() {

          const columns = [
            {
              title: "Token Name",
              field: "name",
              cellStyle: {
                backgroundColor: "orangered",
                color:'white'
            },
            headerStyle: {
                backgroundColor: "rgb(249, 249, 249)", 
            }
            },
            {
                title: "Price",
                field:"price",
                cellStyle: {
                    backgroundColor: "orangered",
                    color:'white'   
                },
                headerStyle: {
                    backgroundColor: "rgb(249, 249, 249)", 
                }
            },
            {
              title: "Total Supply",
              field: "totalSupply",
              cellStyle: {
                backgroundColor: "orangered",
                color:'white'   
            },
              headerStyle: {
                backgroundColor: "rgb(249, 249, 249)", 
            }
            },
            {
              title: "Tokens Locked",
              field: "total",
              render: rowData => <div>  <i className="ti-lock" style={{color:'white'}}></i>  {rowData.total.toString()}           </div>,
              cellStyle: {
                backgroundColor: "orangered",
                color:'white'   
            },
              headerStyle: {
                backgroundColor: "rgb(249, 249, 249)", 
            },
            },
            {
                title: "Tokens Locked %",
                field: "totalpercent",
                cellStyle: {
                    backgroundColor: "orangered",
                    color:'white'   
                },
                headerStyle: {
                    backgroundColor: "rgb(249, 249, 249)", 
                }
            },
            {
                title: "Address",
                field: "address",
                cellStyle: {
                    backgroundColor: "orangered",
                    color:'white'   
                },
                headerStyle: {
                    backgroundColor: "rgb(249, 249, 249)", 
                }
              },
              {
                  title: "",
                  field: "address",
                  render: rowData => <div>
                      <button type="button" class="btn btn-light" onClick={() => this.goToPage(rowData.address)}>View</button>
                  </div>,
                  cellStyle: {
                    backgroundColor: "orangered",
                    color:'white'   
                },
                headerStyle: {
                    backgroundColor: "rgb(249, 249, 249)", 
                }
              }
          ];


          const columnsMobile = [
            {
              title: "Token Name",
              field: "name",
              cellStyle: {
                backgroundColor: "orangered",
                color:'white'
            },
            headerStyle: {
                backgroundColor: "rgb(249, 249, 249)", 
            }
            },
            {
              title: "Tokens Locked",
              field: "total",
              render: rowData => <div>  <i className="ti-lock" style={{color:'white'}}></i>  {rowData.total.toString()} ({rowData.totalpercent})           </div>,
              cellStyle: {
                backgroundColor: "orangered",
                color:'white'   
            },
              headerStyle: {
                backgroundColor: "rgb(249, 249, 249)", 
            },
            },
              {
                  title: "",
                  field: "address",
                  render: rowData => <div>
                      <button type="button" class="btn btn-light" onClick={() => this.goToPage(rowData.address)}>View</button>
                  </div>,
                  cellStyle: {
                    backgroundColor: "orangered",
                    color:'white'   
                },
                headerStyle: {
                    backgroundColor: "rgb(249, 249, 249)", 
                }
              }
          ];
        return (
                 <div className="col-lg-12" style={{textAlign:'center'}}>
                 <br />
                 <BrowserView>
                 {this.state.loading == true ? <p>Loading data...</p> : <MaterialTable
                    title="Lock Explorer"
                    data={this.state.allDeposits}
                    columns={columns}
                    options={{ search: true, paging: true, pageSize: 20}}
                />}
                </BrowserView>
                 
                <MobileView style={{width:'100%'}}>
                {this.state.loading == true ? <p>Loading data...</p> : <MaterialTable
                    title="Lock Explorer"
                    data={this.state.allDeposits}
                    columns={columnsMobile}
                    options={{ search: true, paging: true}}
                />}
                </MobileView>
                
                 </div>
        )
    }
}


export default FrontPageExplorer;
