import React, { Component } from 'react';
import BannerContent from './BannerContent4';
import WOW from "wowjs";


class Banner extends Component {
    componentDidMount() {
    const wow = new WOW.WOW();
        wow.init();
    }
    render() {
        // var {name, topic} = this.props;
        return (
            <section className="n_hero_banner_area4" style ={ { backgroundImage: "-moz-linear-gradient( 40deg, #1c46df 0%, #18d8f6 100%),-webkit-linear-gradient( 40deg, #1c46df 0%, #18d8f6 100%), -ms-linear-gradient( 40deg, #1c46df 0%, #18d8f6 100%)" } } id="home">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12">
                            <BannerContent name="BSCrypt Explorer" topic="The Binance Smart Chain BSCrypt Explorer"/>
                        </div>
                    </div>
                </div>
                <div className="shape_banners">
                    <img className="img1 wow fadeInRight" data-wow-delay="1.6s" src={ require('../../images/home/3dline.png')} alt="f_img" />
                    <img className="img2 wow fadeInRight" data-wow-delay="1.2s" src={require ('../../images/home/3dline2.png')} alt="f_img" />
                    <img className="img3 wow fadeIn" data-wow-delay="1.5s" src={require('../../images/home/3D.png')} alt="f_img" />
                </div>
            </section>
            );
        }
    }

export default Banner;

                
