import React, { Component } from 'react';
import Title from './Title';
import FeaturesItems from './FeaturesItems';

class Features extends Component {
    render() {
        return (
            <section className="best_screen_features_area features_area_pad" id="features">
                <svg xmlns="http://www.w3.org/2000/svg">
                    <path fill="#fff" id="down_bg_copy_2" data-name="down / bg copy 2" className="cls-1" d="M444.936,252.606c-148.312,0-305.161-29.63-444.936-80.214V0H1920V12S808.194,234.074,444.936,252.606Z" />
                </svg>
                <ul className="memphis-parallax hidden-xs hidden-sm white_border">
                    <li data-parallax='{"x": -00, "y": 100}'><img className="br_shape" src={require('../images/line/f_l_01.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 200, "y": 200}'><img className="br_shape" src={require('../images/line/f_l_02.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 150, "y": 150}'><img className="br_shape" src={require('../images/line/f_l_03.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 50, "y": 50}'><img className="br_shape" src={require('../images/line/f_l_05.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 150, "y": 150}'><img src={require('../images/line/f_l_06.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 100, "y": 100}'><img src={require('../images/line/f_l_07.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 50, "y": 50}'><img src={require('../images/line/f_l_08.png')} alt="f_img"/></li>
                    <li data-parallax='{"y": 250}'><img src={require('../images/line/f_l_09.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 250, "y": 250}'><img src={require('../images/line/f_l_10.png')} alt="f_img"/></li>
                    <li data-parallax='{"x": 150, "y": 150}'><img className="br_shape" src={require('../images/line/f_l_04.png')} alt="f_img"/></li>
                </ul>
                <div className="container">
                    <Title Stitle="How it works" pfont="Lock your liquidity or developer tokens in a few simple steps with BSCrypt. Our process is easy, fast and secure."/>
                    <div className="row ">
                        <div className="col-lg-4">
                            <div className="b_screen_img wow fadeInUp"><img src={require('../images/mobile_mockup_2.png')} alt="featured" className="img-fluid"/></div>
                        </div>
                        <div className="col-lg-8">
                            <div className="row b_features_info">
                                <FeaturesItems ftitle="Lock Liquidity" text="Input your liquidity tokens into a time-released vault that returns the tokens at a specified date." name="lock"/>
                                <FeaturesItems ftitle="Team Token Vesting" text="Create a vesting schedule for team members with specified dates and token amounts." name="money"/>
                                <FeaturesItems ftitle="Public Accountability" text="Proudly display your locked tokens and vesting schedules to the world as proof." name="image"/>
                                <FeaturesItems ftitle="Earn investors' Trust" text="Your funds will be locked by the blockchain" name="stats-up"/>
                            </div>
                        </div>
                        <div className="col-lg-12">
                        <a href="/lock" style={{textDecoration:'none'}}>
                        <button class="btn btn-outline-primary" type="button" style={{width:'100%'}}>Create a Lock</button>
                        </a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Features;