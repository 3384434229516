import Web3 from 'web3';

let web3;

if (typeof window !== 'undefined' && typeof window.web3 !== 'undefined')
{
    window.ethereum.enable()
    web3 = new Web3(window.web3.currentProvider)
} else {
    let instance = new Web3.providers.HttpProvider('https://bsc-dataseed.binance.org/');
    web3 = new Web3(instance)
}


export default web3;