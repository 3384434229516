import React, { Component } from 'react';
// import { Route, Switch, Redirect } from 'react-router-dom';

import CustomNavbar from './component/CustomNavbar';
import Banner from './component/Banner/Banner';
import Features from './component/Features';
import Faq from './component/Faq/Faq';
import Footer from './component/Footer/Footer';
import $ from 'jquery';
import Service from './component/Service';
import TokenEconomics from './component/TokenEconomics';
import WOW from 'wowjs';
import { Web3ReactProvider } from '@web3-react/core'
import Web3 from 'web3';
class App extends Component {
    componentDidMount(){
      if (typeof window !== 'undefined') {
        const wow = new WOW.WOW({
          live: false,
        })
        wow.init()
      }
        var head = $(".navbar");
        var stick = "shrink";

        $(window).scroll(function() {
            $(window).scrollTop() > 0
                ? head.addClass(stick)
                : head.removeClass(stick)
        })

        $('.navbar li a').on('click', function(event) {
            var $anchor = $(this);
            console.log($anchor.attr('href'))
            if($anchor.attr('href') != "/BSCrypt_Audit_Report_-_QuillAudits.pdf"){
              $('html, body').stop().animate({
                scrollTop: $($anchor.attr('href')).offset().top-75
            }, 1500);
            event.preventDefault();
            }

        });
    }

    getLibrary(provider, connector) {
      return new Web3(provider) // this will vary according to whether you use e.g. ethers or web3.js
    }


  render() {
    return (
        <div className="App">
              <Web3ReactProvider getLibrary={this.getLibrary}>
                <CustomNavbar/>
                <Banner/>
                <Features/>
                <br />
                <Service />
                {/* <br />
                <TokenEconomics /> */}
                {/* <hr /> */}
                {/* <h2>Our Roadmap</h2> */}
                    {/* <img src={require('./images/BSCRYPT ROADMAP.png')} style={{width:'100%', padding:'25px'}}  /> */}
                <Faq/>
                <br /> <br />
                {/* <hr /> */}
                <br /> <br />
                <Footer/>
                </Web3ReactProvider>
        </div>
    )
  }
}

export default App;
