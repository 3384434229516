import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import About from './About';
import App from './App';
import LockToken from './LockToken';
import Explorer from './Explorer';
import Explorer_advanced from './Explorer_advanced';

import * as serviceWorker from './serviceWorker';
import { Route, BrowserRouter, Switch } from "react-router-dom";

import './assets/bootstrap.min.css';
import './assets/animate.css';
import './assets/elagent/style.css';
import './assets/themify-icon/themify-icons.css';
import './assets/font-awesome/font-awesome.min.css';
import './assets/main.css';
import './assets/responsive.css';


ReactDOM.render(
    <BrowserRouter>
        <Switch>
        <Route exact path="/" component={App} />
        <Route path="/lock" component={LockToken} />
        <Route exact path="/explorer" component={Explorer} />
        <Route exact path="/about" component={About} />
        <Route path="/explorer/:smartcontractaddress" component={Explorer_advanced}/> 


        </Switch>


    </BrowserRouter>,
    document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
