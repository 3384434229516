import React, { Component } from 'react';
import Title from '../Title'
import web3 from '../../constants/web3';
import lockToken from '../../constants/lockToken';
import {Modal, Button} from 'react-bootstrap';
import {ERC20ABI} from '../../constants/erc20abi';
import MaterialTable from "material-table";
import payContract from '../../constants/payContract';


class Testimonial extends Component {



    state = {
        deposits: [],
        hasPaid: true,
    }

    componentDidMount(){
        this.getUsersLocks()
        this.checkBalance();
    }

    checkBalance = async() => {
        let account = await web3.eth.getAccounts();
        const paid = await payContract.methods.payments(account[0]).call();
        if(paid == false){
            this.setState({hasPaid:false})
        }
    }

    payLock = async() =>{
        try{
            let account = await web3.eth.getAccounts();
            const x = await payContract.methods.pay().send({
                from: account[0],
                value: 300000000000000000
            })
            alert("You have successfully unlocked!")
            window.location.reload();
        }catch(err){

        }
    }

    getUsersLocks = async() => {
        try{
            let account = await web3.eth.getAccounts();
            let locks = await lockToken.methods.getDepositsByWithdrawalAddress(account[0]).call();
            console.log(locks)
            let objects = []
                for (var i = locks.length - 1; i >= 0; i--)
                {
                let obj = {}
                let depositDetails = await lockToken.methods.getDepositDetails(locks[i]).call();
                const erc20token = new web3.eth.Contract(ERC20ABI,depositDetails[0]);
                
                let name = await erc20token.methods.name().call();
                let decimals = await erc20token.methods.decimals().call();
                let deposit = depositDetails[2]
                const multiplier = Math.pow(10,decimals);
                deposit = (deposit / multiplier)
                deposit = parseFloat(deposit).toFixed(decimals);
                
                var today = new Date();
                const deadline = new Date(depositDetails[3]*1000)
                var Difference_In_Time = deadline.getTime() - today.getTime(); 
                var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 
                if(Difference_In_Days < 0){
                    Difference_In_Days = 0;
                } else {
                    Difference_In_Days = this.getTimeRemaining(deadline);
                    
                    Difference_In_Days = `${Difference_In_Days.days} days, ${Difference_In_Days.hours} hours, ${Difference_In_Days.minutes} minutes and ${Difference_In_Days.seconds} seconds`
                }

                console.log(deadline)
                obj["id"] = locks[i];
                obj["name"] = name;
                obj["address"] = depositDetails[0];
                obj["deposit"] = deposit
                obj["deadline"] = Difference_In_Days
                obj["canwithdraw"] = depositDetails[4]
                
                objects.push(obj)

            }
            this.setState({deposits:objects})
        }
        catch(err){
            console.log(err)
        }
    }


    getTimeRemaining = (endtime) => {
        const total = Date.parse(endtime) - Date.parse(new Date());
        const seconds = Math.floor( (total/1000) % 60 );
        const minutes = Math.floor( (total/1000/60) % 60 );
        const hours = Math.floor( (total/(1000*60*60)) % 24 );
        const days = Math.floor( total/(1000*60*60*24) );
      
        return {
          total,
          days,
          hours,
          minutes,
          seconds
        };
      }



    withDraw = async(id) => {
        try{
            let account = await web3.eth.getAccounts();
            await lockToken.methods.withdrawTokens(id).send({
                from: account[0]
            })
            window.location.reload()
        } catch(err){
            console.log(err.message);
        }
    }

    render() {

        const columns = [
            {
              title: "Token Name",
              field: "name",
            },
            {
                title: "Token Address",
                field: "address",
              },
            {
              title: "Deposit",
              field: "deposit",
            },
            {
              title: "Remaining Days",
              field: "deadline",
            },
            {
                title: "Withdrawn",
                field:"canwithdraw",
                render: rowData => <div> {rowData.canwithdraw == false && rowData.deadline == "0.00" ? (
                    this.state.hasPaid == true ?
                    <div>
                    <button type="button" class="btn btn-warning" onClick={() => this.withDraw(rowData.id)}>Withdraw</button>
                    </div> : <div>  
                            <p>To unlock BSCRYPT lock feature, you must first pay 0.3 BNB. Infinite locks available on payment.</p>
                            <button class="btn btn-outline-warning" onClick={this.payLock}>Pay</button>
                             </div>) : rowData.canwithdraw.toString()}       </div>,
            }
          ];
        return (
            <section className="testimonial_area_two">
                <div className="container">
                    <Title Stitle="My Locks" pfont="See details about your previous locks"/> 
                    <div id="carouselExampleIndicators" className="carousel" data-ride="carousel">
                    <MaterialTable
                    title="Your locks"
                    data={this.state.deposits}
                    columns={columns}
                    options={{ search: false, paging: true}}
                />
                     </div>
                </div>
            </section>
        );
    }
}

export default Testimonial;