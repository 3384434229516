import React, { Component } from 'react';
import CustomNavbar from './component/CustomNavbar';
import $ from 'jquery';
import Banner3 from './component/Banner/Banner3';
import Footer from './component/Footer/Footer';
import WOW from 'wowjs';

class Explorer extends Component {

    componentDidMount(){
        if (typeof window !== 'undefined') {
            const wow = new WOW.WOW({
              live: false,
            })
            wow.init()
          }
            var head = $(".navbar");
            var stick = "shrink";
    
            $(window).scroll(function() {
                $(window).scrollTop() > 0
                    ? head.addClass(stick)
                    : head.removeClass(stick)
            })
    
            $('.navbar li a').on('click', function(event) {
                var $anchor = $(this);
                console.log($anchor.attr('href'))
                if($anchor.attr('href') != "/BSCrypt_Audit_Report_-_QuillAudits.pdf"){
                  $('html, body').stop().animate({
                    scrollTop: $($anchor.attr('href')).offset().top-75
                }, 1500);
                event.preventDefault();
                }
    
            });
        }


    render() {
        return (
            <div className="App">
             <CustomNavbar/>
                <Banner3/>
                {/* <About/> */}
                <br /> <br />
                {/* <Price/> */}
                {/* <Action/> */}
                {/* <AppStore/> */}
                <Footer/>
            </div>

        )


}

}
export default Explorer;